.headerLink {
    font-size: 1.2em !important;
    color: gray !important;
    text-decoration: none;
    margin-right: 1em !important;
}
.headerLink.active {
    color: lightgray !important;
}
.footerText {
    font-size: 0.75em !important;
    color: gray !important;
    text-align: left;
}

.loginButton {
    color: #000000 !important;
    background-color: #F7941D !important;
    border-color: #F7941D !important;
}

.changePasswordButton {
    color: #000000 !important;
    background-color: #F7941D !important;
    border-color: #F7941D !important;
    margin-bottom: 1em !important;
}

.smallLabel {
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    */font-size: 0.75em !important;
    margin-bottom: 5px !important;
}

.bigLabel {
    font-size: 2.5em !important;
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
*/}

.smallPlaceholder {
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
   */ font-size: 1.25em !important;
}

.filterClearButton {
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
    height: 2.5rem;
}

.filterButtonRight {
    border-radius: 0 0.25rem 0.25rem 0;
    height: 2.5rem;
}

.filterInputLeft {
    border-radius: 0.25rem 0 0 0.25rem;
    border: 1px solid rgb(229, 229, 229);
    height: 2.5rem;
    box-shadow: none;
    max-width: 10rem;
}

.filterContainer {
    grid-column-gap: 0.75rem;
}

.dateDiv {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 5px;
}

.orderTable {
    margin-left: 16px;
}

.orderTableLabel {
    margin-left: 16px;
    margin-bottom: 8px;
}

.newResellerLabel {
    margin-left: 16px;
    margin-bottom: 8px;
}

.newResellerInput {
    margin-left: 16px;
    margin-bottom: 4px;
    margin-right: 4px;
}

.orderTableRadio {
    margin-left: 16px;
    margin-bottom: 4px;
    margin-right: 4px;
}

.orderTableRadioDiv {
    margin-bottom: 16px;
}

table.orderTable {
    width: 99%;
}

.dateDiv label {
    text-align: right;
    display: flex;
    align-items: center;
}

/*.dateDiv label:after {
    content: ":";
}*/

.orderId {
    width: 12rem;
    height: 2.5rem;
    text-align: center;
}


.dateFilter {
    width: 12rem;
    height: 2.5rem;
    text-align: center;
}

.styledDropdown {
    width: 10rem;
    height: 2.5rem;
    text-align: center;
}

.dateLabel {
    height: 2.5rem;
    white-space: nowrap;
    margin-right: auto;
}

.exportButton {
    height: 2.5rem;
    background-color: #F7941D !important;
    border-color: #F7941D !important;
    white-space: nowrap;
}

.newOrderButton {
    height: 2.5rem;
    white-space: nowrap;
}

.deleteButton {
    background-color: #F7941D !important;
    border-color: #F7941D !important;
    color: white !important;
}

#loadingSpinner {
    color: #F7941D !important;
}

#loadingSpinnerDiv {
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0,0,0); /* Black fallback color */
    background-color: rgba(0,0,0, 0.7); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s;
}

#loadingSpinnerDivNoBackground {
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s;
}